import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpConfigService, ReefAuthToken, ReefGatewayService, ReefLoginAccessTokenOutput } from '@seco/core';
import { ClpLoginResponse, ClpService } from '@seco/login';
import { UpdateClpConfig, UserLoggedIn, UserSessionExpired } from '../store/core/actions';

import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '../core/util/logger';
import { SessionStorageUtils } from '../core/util/session-storage';
import { AirpCoreState } from '../store/core/state';
import { LoggedInUser } from './model/logged-in-user';

@Injectable()
export class LoginService {
  INTRANET_URL = 'intranet';

  constructor(
    // NOSONAR
    private readonly zone: NgZone,
    private readonly store: Store<AirpCoreState>,
    private readonly httpConfigService: HttpConfigService,
    private readonly router: Router,
    private readonly gwService: ReefGatewayService,
    private readonly clpService: ClpService
  ) {}

  /**
   * Check if the context url is call from intranet or internet usage
   *
   * @returns True or False
   */
  isIntranet(): boolean {
    const url = window.location !== window.parent.location ? document.referrer : document.location.href;

    return url.indexOf(this.INTRANET_URL) >= 0;
  }

  /**
   * Parse the CLP Auth URL provided by the reef-gateway
   *
   * The CLP Auth url is configured with internet CLP login url, in case of intranet usage
   * we need to add 1a to the URL as workaround.
   *
   * @param clpAuthUrl the clp authorization url to parse
   * @returns clpAuthUrl adapted for intranet or internet usage
   */
  parseClpAuthUrl(clpAuthUrl: string): string {
    return this.isIntranet()
      ? clpAuthUrl.replace(/(dev|pdt|mig|uat|skl|btprd|www)/i, '1a.$1').replace('www.', '')
      : clpAuthUrl;
  }

  /**
   * Get token and nonce by calling UM action.
   */
  getClpConfig() {
    this.gwService.getAuthenticationToken().subscribe((res: ReefAuthToken) => {
      this.store.dispatch(
        new UpdateClpConfig({
          token: res.configToken,
          nonce: res.nonce,
          baseUrl: this.getClpHost(res.clpAuthURL),
          loginParameters: {
            officeNeeded: true,
            displayDutyCode: false
          }
        })
      );
    });
  }

  /**
   * Retrieves the CLP host from the session storage or parses it from the CLP auth URL.
   * @param clpAuthURL - The CLP auth URL.
   * @returns The CLP host.
   */
  getClpHost(clpAuthURL) {
    return SessionStorageUtils.getCustomerInputs()?.clpHost || this.parseClpAuthUrl(clpAuthURL);
  }

  /**
   * Log user from the session storage / cookies
   */
  loginBySession(): boolean {
    Logger.debug(`[LoginService] trying to log from session`);
    if (this.isConnected()) {
      const user: LoggedInUser = this.getLoggedUser();

      Logger.debug(`User ${JSON.stringify(user)} is still valid`);

      const decodedIdToken = jwt_decode(user.idToken);

      this.loginByAccessToken({
        accessToken: user.accessToken,
        idToken: user.idToken,
        nonce: decodedIdToken.nonce
      });

      return true;
    }

    return false;
  }

  /**
   * Login by access token
   */
  loginByAccessToken(clpLoginResponse: ClpLoginResponse) {
    const loginData = {
      accessToken: clpLoginResponse.accessToken,
      idToken: clpLoginResponse.idToken,
      nonce: clpLoginResponse.nonce
    };

    // transmit the access token got from CLP from the authentication to the reef gateway to be used later on by the reef connect
    this.gwService.loginInByAccessToken(loginData).subscribe((res: ReefLoginAccessTokenOutput) => {
      // have to call inside zonejs to trigger change detection, CLP code is run by default outside zonejs
      this.zone.run(() => {
        this.httpConfigService.config.jSessionId = res.JsessionId;
        this.store.dispatch(
          new UserLoggedIn({
            userAlias: res.USER_ALIAS,
            userId: res.USER_ID as string,
            officeId: res.LOGIN_AREA_ID as string,
            organization: res.ORGANIZATION as string
          })
        );
      });
    });
  }

  /**
   * Check if the user is connected and token has not expired
   * @returns True if user is connected and token is still valid.
   */
  isConnected(): boolean {
    const user = this.getLoggedUser();

    return !!user && moment().isBefore(moment(user.expirationTime));
  }

  getLoggedUser(): LoggedInUser {
    return SessionStorageUtils.getClpUser();
  }

  logOutUser() {
    this.clpService.logout(JSON.stringify({ postLogoutRedirectUri: this.router.url }), this.clpLogoutSuccess);
    this.gatewayLogout();
  }

  clpLogoutSuccess(): void {
    Logger.debug('CLP logout success');
  }

  permissionFailedLogout() {
    this.clpService.logout(JSON.stringify({ postLogoutRedirectUri: this.router.url }), this.clpLogoutSuccess);
  }

  gatewayLogout(): void {
    // Call to reef gateway
    this.gwService
      .logout()
      .pipe(catchError((error) => of(new UserSessionExpired(error.message))))
      .subscribe(() => {
        this.zone.run(() => {
          Logger.debug('logout triggered successfully');
          // Page reload requested to call the configToken action to have a new nonce
          window.location.reload();
        });
      });
  }
}
