import { AirpCustomerParameters } from '../../model/airp-customer-parameters';
import { LoggedInUser } from '../../service/model/logged-in-user';

/**
 * Session Storage helper used to manage local storage for the application
 * - clp-auth-token: store the tokens and LSS user related properties
 * - airp-customer-parameters: store the customer inputs when calling AIRP
 */
export class SessionStorageUtils {

  /**
   * Session storage's name used to store the clp user information
   */
  static readonly LOCAL_STORAGE_KEY_CLP_TOKEN_BASE: string = 'clp-auth-tokens';
  /**
   * Session storage's name used to store the customer inputs
   */
  static readonly LOCAL_STORAGE_KEY_PARAMS = 'airp-customer-parameters';

  /**
   * Save the CLP User to the session storage
   * @param value the LoggedInUser
   */
  static saveClpUser(value: LoggedInUser) {
    this.setItem(SessionStorageUtils.LOCAL_STORAGE_KEY_CLP_TOKEN_BASE, value);
  }

  /**
   * Get the CLP user from the session storage
   * @returns the clp user
   */
  static getClpUser(): LoggedInUser | null {
    return this.getItem(SessionStorageUtils.LOCAL_STORAGE_KEY_CLP_TOKEN_BASE);
  }

  /**
   * Remove the CLP user from the session storage
   */
  static removeClpUser() {
    sessionStorage.removeItem(SessionStorageUtils.LOCAL_STORAGE_KEY_CLP_TOKEN_BASE);
  }

  /**
   * Save the customer inputs to the session storage that has been extracted from the query params
   * @param customerParameters the customer parameter to save
   */
  static saveCustomerInputs(customerParameters: AirpCustomerParameters) {
    this.setItem(SessionStorageUtils.LOCAL_STORAGE_KEY_PARAMS, customerParameters);
  }

  /**
   * Get the customer inputs parameter from the session storage
   * @returns the customer inputs
   */
  static getCustomerInputs(): AirpCustomerParameters | null {
    return this.getItem(SessionStorageUtils.LOCAL_STORAGE_KEY_PARAMS);
  }

  /**
   * Remove customer inputs from storage
   */
  static removeCustomerInputs() {
    sessionStorage.removeItem(SessionStorageUtils.LOCAL_STORAGE_KEY_PARAMS);
  }

  /**
   * Save an item to the session storage associated to a key
   * @param key the key of the session storage
   * @param value the value to save
   */
  static setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Get a item from the store
   * @param key the key of the session storage to get
   * @returns the object value extract or null
   */
  static getItem(key: string): any | null {
    const item = sessionStorage.getItem(key);

    if (item) {
      return JSON.parse(item);
    }

    return null;
  }

  /**
   * Remove an item from the session storage by its key
   * @param key the key of the session to remove
   */
  static removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
