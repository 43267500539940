import {Inject, Injectable, NgZone, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ReefGatewayService, ReefKeepAliveService} from '@seco/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class KeepAliveService extends ReefKeepAliveService implements OnDestroy {

  interval: ReturnType<typeof setInterval> = null;
  userWasActiveLastPeriod = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    zone: NgZone,
    httpClient: HttpClient,
    gwService: ReefGatewayService) {
    super(zone, httpClient, gwService);
  }

  start() {
    this.stop(); // only one instance should run at the same time
    super.start();
    this.document.onmousemove = this.registerUserAction.bind(this);
    this.document.onkeydown = this.registerUserAction.bind(this);
    this.document.onclick = this.registerUserAction.bind(this);
    this.interval = setInterval(() => this.sendKeepAliveIfActive(), 60000);
  }

  stop() {
    super.stop();
    clearInterval(this.interval);
  }

  private registerUserAction() {
    this.userWasActiveLastPeriod = true;
  }

  sendKeepAliveIfActive() {
    if (this.userWasActiveLastPeriod) {
      window.postMessage('AIRP - User activity', window.parent.location.origin);
    }
    this.userWasActiveLastPeriod = false;
  }

  ngOnDestroy(): void {
    this.stop();
  }
}
