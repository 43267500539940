import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LoginService } from '../../service/login.service';
import { CORE_ACTION, SetAppIsReady } from './actions';

import {
  AirpActions,
  CORE_INIT_STARTED,
  CoreInitStarted,
  PnrService
} from '@seco/insurance';
import { Logger } from '../../core/util/logger';
import { SessionStorageUtils } from '../../core/util/session-storage';
import { AirpCustomerParameters } from '../../model/airp-customer-parameters';
import { selectLoggedInUser } from '../core/selector';
import { AirpCoreState } from './state';

@Injectable()
export class Effects {
  constructor(
    private readonly actions$: Actions,
    private readonly loginService: LoginService,
    private readonly pnrService: PnrService,
    private readonly store: Store<AirpCoreState>
  ) {}

  /**
   * Action triggered when the user session has expired
   * Reload the window to reinitiate the login
   *
   * TODO: In case of SSO this can be an issue TBC
   */
  logOutAfterSessionExpired$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CORE_ACTION.USER_SESSION_EXPIRED),
      tap(() => {
        this.loginService.permissionFailedLogout();
        window.location.reload();
      })
    )
  );

  /**
   * Action triggered when the USER has been authentified
   * Trigger the first PNR retrieve before that the Insurance application really start
   *
   * /!\ At this stage we cannot dispath `AirpActions.customerInputParametersSet` to airp template
   * because the insurance RESET action will erase the stage from the store
   */
  initPnrRequestWhenUserLogged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CORE_ACTION.USER_LOGGED_IN),
      // Retrieve the PNR
      switchMap(() => {
        const customerInputs: AirpCustomerParameters = SessionStorageUtils.getCustomerInputs();

        Logger.debug(`User loggeed, trying to retrieve PNR from query params ${JSON.stringify(customerInputs)}`);

        return this.pnrService.pnrRetrieve(customerInputs?.pnrRecLoc)
          .pipe(
            take(1),
            map(() => new SetAppIsReady(true)),
            catchError((response) => [
              new SetAppIsReady(true),
              AirpActions.airpErrorOccurred( { error: response } )
            ])
          );
      })
    )
  );

  /**
   * Action triggered when the Insurance app start to propagate to the store the customer inputs
   */
  reloadCustomerInputParameters$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CoreInitStarted>(CORE_INIT_STARTED),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      map(([, user]) => {
        // Retrieve the query parameter from the session storage
        const customerInputs: AirpCustomerParameters = Object.assign({},
          SessionStorageUtils.getCustomerInputs(),
          { userId: user.userId }
        );

        // Propagage the customer config to the AIRP store
        return AirpActions.customerInputParametersSet({ customerInputParameters: customerInputs });
      })
    )
  );

}
