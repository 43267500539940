import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AirpCoreState, CORE_FEATURE } from './state';

export const selectCore = createFeatureSelector<AirpCoreState>(CORE_FEATURE);

export const selectAppIsReady = createSelector(selectCore, (state: AirpCoreState) => state.appIsReady);

export const selectClpConfig = createSelector(selectCore, (state: AirpCoreState) => state.clpConfig);

export const selectLoggedIn = createSelector(selectCore, (state: AirpCoreState) => state.loggedIn);

export const selectLoggedInUser = createSelector(selectCore, (state: AirpCoreState) => (state ? state.user : undefined));

export const selectClpError = createSelector(selectCore, (state: AirpCoreState) => state.error);

export const selectAppError = createSelector(selectCore, (state: AirpCoreState) => state.appError);
