import { ClpInitConfig, LoginAlertModel } from '@seco/login';
import { LoggedInUser } from '../../service/model';

export const CORE_FEATURE = 'coreAirpUi';

export interface AirpCoreState {
  clpUrl?: string;
  clpConfig?: ClpInitConfig;
  loggedIn: boolean;
  user?: LoggedInUser;
  error?: LoginAlertModel;
  sending: boolean;
  appIsReady: boolean;
  appError?: string;
}

export const coreInitialState: AirpCoreState = {
  loggedIn: false,
  sending: false,
  appIsReady: false
};
