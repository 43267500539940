import { CLP_ACTION } from '@seco/login';
import { CORE_ACTION, CoreActions } from './actions';
import { AirpCoreState, coreInitialState } from './state';

export const coreReducer = (state = coreInitialState, action: CoreActions): AirpCoreState => {
  switch (action.type) {
    case CORE_ACTION.UPDATE_CLP_CONFIG:
      return Object.assign({}, state, {
        clpConfig: action.payload
      });
    case CORE_ACTION.USER_LOGGED_IN:
      return Object.assign({}, state, {
        loggedIn: true,
        user: action.payload,
        error: false
      });
    case CLP_ACTION.ERROR_RECEIVED:
    case CORE_ACTION.USER_SESSION_EXPIRED:
      return Object.assign({}, state, {
        loggedIn: false,
        error: action.payload
      });
    case CORE_ACTION.SET_APP_IS_READY:
      return Object.assign({}, state, {
        appIsReady: action.payload
      });
    case CORE_ACTION.SET_APP_ERROR:
      return Object.assign({}, state, {
        appError: action.payload
      });
    default:
      return state;
  }
};
