import { Action } from '@ngrx/store';
import { ClpInitConfig } from '@seco/login';
import { LoggedInUser } from '../../service/model';

export const CORE_ACTION = {
  UPDATE_CLP_CONFIG: '[AIRP-UI][Core Action] CLP config updated',
  USER_LOGGED_IN: '[AIRP-UI][Core Action] User is logged in',
  USER_SESSION_EXPIRED: '[AIRP-UI][Core Action] User session expired',
  SET_APP_IS_READY: '[AIRP-UI][Core Action] Set application readiness',
  SET_APP_ERROR: '[AIRP-UI][Core Action] Set application error'
};

export class UpdateClpConfig implements Action {
  readonly type = CORE_ACTION.UPDATE_CLP_CONFIG;
  constructor(public payload: ClpInitConfig) {}
}

export class UserLoggedIn implements Action {
  readonly type = CORE_ACTION.USER_LOGGED_IN;
  constructor(public payload?: LoggedInUser) {}
}

export class UserSessionExpired implements Action {
  readonly type = CORE_ACTION.USER_SESSION_EXPIRED;
  constructor(public payload?: string) {}
}

export class SetAppIsReady implements Action {
  readonly type = CORE_ACTION.SET_APP_IS_READY;
  constructor(public payload?: boolean) {}
}

export class SetAppError implements Action {
  readonly type = CORE_ACTION.SET_APP_ERROR;
  constructor(public payload: string) {}
}

export type CoreActions = UpdateClpConfig | UserLoggedIn | UserSessionExpired;
