<div class="design-factory-v2 amadeus-style" *ngIf="loggedIn$ | async; else exception">
  <div *ngIf="isStandaloneDemoApp()">
    <div class="form-inline mt-3">
      <input #locatorInput class="form-label ms-3" placeholder="locator" type="text" value="VJ33N4"/>
      <button (click)="getPnr(locatorInput.value)" class="btn btn-primary ms-3">Retrieve PNR</button>
      <button (click)="goToSearch()" class="btn btn-primary ms-3">Go to Search</button>
      <button (click)="loadConfig('PR')" class="btn btn-primary ms-3">Default config (Philippine Airlines)</button>
      <button (click)="loadConfig('TX')" class="btn btn-primary ms-3">AirCaraibes config</button>
      <button (click)="loadConfig('NO_SPECIAL_FIELDS')" class="btn btn-primary ms-3"
        >No special fields</button
      >
    </div>
  </div>
  <div class="ng-loader" *ngIf="appIsReady$ | async">
    <ama-ng-insurance></ama-ng-insurance>
  </div>
</div>
<ng-template #exception>
  <div class="design-factory-v2 amadeus-style" *ngIf="appError$ | async">
    <div class="text-center mt-6">
      <h1 i18n="@@airp.global.exception">Something went wrong, please check with the administrator.</h1>
    </div>
  </div>
</ng-template>

