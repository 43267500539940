import { Injectable } from '@angular/core';

/**
 * This class is used to determine in which environment the application is started. Defaults to "prd"
 */
@Injectable({
  providedIn: 'root'
})
export class CurrentEnvironmentService {

  /**
   * Determines the current environment the application is started in. Defaults to "prd"
   */
  determineCurrentEnvironment(): string {
    // test.ardw-insurance.airlines.amadeus.com  --> airpui.pdt.muc11.tnz.amadeus.net
    // acceptance.ardw-insurance.airlines.amadeus.com --> airpui.uat.muc11.tnz.amadeus.net
    // ardw-insurance.airlines.amadeus.com --> airpui.muc11.tnz.amadeus.net
    const loc = this.getWindowLocationsHostnameInLowerCase();

    if (loc.startsWith('ardw-insurance.')) {
      return 'prd';
    }
    if (loc.startsWith('acceptance.ardw-insurance')) {
      return 'uat';
    }
    if (loc.startsWith('test.ardw-insurance') || loc.startsWith('localdev.amadeus') || loc.includes('airpui-airp-')) {
      return 'pdt';
    }
    console.warn('[AIRP] Could not determine environment - default to PRD');

    return 'prd';
  }

  getWindowLocationsHostnameInLowerCase() {
    return window.location.hostname.toLowerCase();
  }
}
