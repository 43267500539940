export enum LogLevel {
  DISABLED = 0,
  ERROR = 1,
  WARNING = 2,
  INFO = 3,
  DEBUG = 4,
}

export class Logger {
  static LOG_LEVEL: LogLevel = LogLevel.DEBUG;

  static isLogEnabled(logLevel: LogLevel): boolean {
    return LogLevel[Logger.LOG_LEVEL] !== undefined && logLevel <= Logger.LOG_LEVEL;
  }

  /**
   * Log error message only if log level allow it
   *
   * @param error the error message to log
   */
  static error(error: any): void {
    Logger.log(error, LogLevel.ERROR);
  }

  /**
   * Log warning message only if log level allow it
   *
   * @param warning the warning message to log
   */
  static warning(warning: any): void {
    Logger.log(warning, LogLevel.WARNING);
  }

  /**
   * Log info message only if log level allow it
   *
   * @param info the info message to log
   */
  static info(info: any): void {
    Logger.log(info, LogLevel.INFO);
  }

  /**
   * Log debug message only if log level allow it
   *
   * @param debug the debug message to log
   */
  static debug(debug: any): void {
    Logger.log(debug, LogLevel.DEBUG);
  }

  static log(msg: any, logLevel: LogLevel): void {
    // console.log(`logLevel=${logLevel} message=${msg}`);
    if (this.isLogEnabled(logLevel)) {
      switch (logLevel) {
        case LogLevel.ERROR:
          console.error(msg);
          break;
        case LogLevel.WARNING:
          console.warn(msg);
          break;
        case LogLevel.INFO:
          console.info(msg);
          break;
        case LogLevel.DEBUG:
          console.debug(msg);
          break;
      }
    }
  }
}
