import { PlatformLocation } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { HttpConfigService } from '@seco/core';
import * as CoreActions from '@seco/insurance';
import { AirpActions, InsuranceActions } from '@seco/insurance';
import { LoginAlertModel, LoginConfigService } from '@seco/login';
import { Observable } from 'rxjs';
import { Logger } from './core/util/logger';
import { PostMessageHelper } from './core/util/post-message-helper';
import { SessionStorageUtils } from './core/util/session-storage';
import { KeepAliveService } from './service/keep-alive/keep-alive.service';
import { LoginService } from './service/login.service';
import { LoggedInUser } from './service/model';
import {
  selectAppError,
  selectAppIsReady,
  selectClpError,
  selectLoggedIn,
  selectLoggedInUser
} from './store/core/selector';
import { AirpCoreState } from './store/core/state';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  appName = 'Amadeus Insurance Reef Platform UI';

  appIsReady$: Observable<boolean>;
  loggedIn$: Observable<boolean>;
  loggedInUser$: Observable<LoggedInUser>;
  clpError$: Observable<LoginAlertModel>;
  appError$: Observable<string>;

  constructor(
    private readonly store: Store<AirpCoreState>,
    private readonly loginConfigService: LoginConfigService,
    private readonly loginService: LoginService,
    private readonly location: PlatformLocation,
    private readonly keepAliveService: KeepAliveService,
    private readonly httpConfigService: HttpConfigService
  ) {
    if (this.isIframeMode()) {
      window.addEventListener('message', PostMessageHelper.receiveInitMessage.bind(this), false);
    }
  }

  ngOnInit(): void {
    this.appIsReady$ = this.store.pipe(select(selectAppIsReady));
    this.appError$ = this.store.pipe(select(selectAppError));
    this.loggedIn$ = this.store.pipe(select(selectLoggedIn));
    this.loggedInUser$ = this.store.pipe(select(selectLoggedInUser));
    this.clpError$ = this.store.pipe(select(selectClpError));
    this.keepAliveService.start();
    if (!window.location.href.includes('access_token')) {
      this.loginService.getClpConfig();
    }
    if (this.isIframeMode()) {
      if (!PostMessageHelper.isInitMessageReceivedFromHost) {
        PostMessageHelper.requestInitMessage();
      }
      this.loggedIn$.subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          PostMessageHelper.sendSessionIdMessage(this.httpConfigService.config.jSessionId);
        }
      });
    }

    // Todo, for standalone we should use the login component to benefit of @seco/login
  }

  ngOnDestroy() {
    this.signOffUser();
    SessionStorageUtils.removeCustomerInputs();
    if (this.isIframeMode()) {
      window.removeEventListener('message', PostMessageHelper.receiveInitMessage, false);
    }
  }

  isIframeMode() {
    return window.top !== window.self;
  }

  signOffUser() {
    this.loginService.logOutUser();
    this.keepAliveService.stop();
  }

  getPnr(locator: string) {
    this.store.dispatch(new CoreActions.CorePNRRequest(locator));
  }

  goToSearch(): void {
    this.restart();
  }

  restart(): void {
    this.store.dispatch(new InsuranceActions.Restart());
  }

  loadConfig(configName: string): void {
    this.store.dispatch(
      AirpActions.loadCustomerConfiguration({
        airlineId: configName,
        market: 'DEFAULT',
        env: SessionStorageUtils.getCustomerInputs()?.env
      })
    );
  }

  /**
   * Check if the application was run in standalone to demo insurance
   */
  isStandaloneDemoApp(): boolean {
    return !this.isIframeMode();
  }

  // Clean JSESSIONID cookie before closure
  @HostListener('window:unload', ['$event'])
  clearBeforeClosure(event: Event) {
    Logger.debug('Signoff event' + event);
    this.signOffUser();
  }
}
