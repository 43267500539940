import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AccessGuardService } from './service/access-guard/access-guard.service';
import { ParameterGuardService } from './service/parameter-guard/parameter-guard.service';

const routes: Routes = [
  {path: '**', component: AppComponent, canActivate: [ParameterGuardService, AccessGuardService]}
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
